<template>
  <div>
    <vx-card title="Date de validité du tarif et frais annexes" class="mb-4">
      <div slot="no-body">
        <div class="aps-benin-v2-content">
          <div v-if="loading">
            <clip-loader :loading="loading" color="primary"></clip-loader>
          </div>

          <div v-else>
            <div>
              <div>
                <div class="con-exemple-prompt mb-8">
                  <div class="w-full">
                    <vs-textarea
                      v-model="validityDate"
                      v-validate="'required'"
                    />
                    <div v-if="displayValidityDate">
                      <p class="text-danger">Ce champ est obligatoire</p>
                    </div>
                  </div>
                </div>

                <div class="pt-2 pb-2">
                  <div class="inline-flex">
                    <div>
                      <vs-button
                        v-if="checkUserRole('customer_document').update"
                        :disabled="!validateForm || loadingValidityDate"
                        @click="fetchApplyUpdateValidityDate"
                        class="w-full sm:w-auto"
                        >Mettre à jour</vs-button
                      >
                      <vx-tooltip
                        color="primary"
                        :title="role.notAllowTitle"
                        :text="role.notAllowText"
                        v-else
                      >
                        <vs-button :disabled="true" class="w-full sm:w-auto m-2"
                          >Mettre à jour</vs-button
                        >
                      </vx-tooltip>
                    </div>
                    <div class="w-auto" v-if="loadingValidityDate">
                      <clip-loader
                        :loading="loadingValidityDate"
                        color="primary"
                      ></clip-loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <vx-card class="mb-4" title="Documents Clients">
      <div slot="no-body">
        <div class="p-8">
          <div>
            <vs-prompt
              title="Exportation"
              class="export-options"
              @cancel="clearFields"
              @accept="exportToData"
              accept-text="Valider"
              cancel-text="Annuler"
              @close="clearFields"
              :active.sync="activePrompt"
            >
              <vs-input
                v-model="fileName"
                placeholder="Entrez le nom du fichier..."
                class="w-full"
              />
              <v-select
                placeholder="--Choisir--"
                v-model="selectedFormat"
                :options="formats"
                class="my-4"
              >
                <span slot="no-options">
                  {{ global.selectNotFoundData }}
                </span>
              </v-select>
              <div class="flex">
                <span class="mr-4">Largeur automatique de cellule:</span>
                <vs-switch v-model="cellAutoWidth"
                  >Largeur automatique de cellule</vs-switch
                >
              </div>
            </vs-prompt>
            <div
              class="
                flex flex-wrap-reverse
                items-center
                data-list-btn-container
              "
            >
              <div>
                <vs-button
                  v-if="checkUserRole('customer_document').create"
                  class="mr-1"
                  @click="popupAddDocumentActive = true"
                  color="primary"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-plus"
                  >Ajouter</vs-button
                >
                <vx-tooltip
                  color="primary"
                  :title="role.notAllowTitle"
                  :text="role.notAllowText"
                  v-else
                >
                  <vs-button
                    :disabled="true"
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                    >Ajouter</vs-button
                  >
                </vx-tooltip>
              </div>

              <div>
                <div>
                  <vs-dropdown class="dd-actions cursor-pointer ml-4">
                    <div
                      class="
                        p-3
                        shadow-drop
                        rounded-lg
                        d-theme-dark-bg
                        cursor-pointer
                        flex
                        items-center
                        justify-center
                        text-lg
                        font-medium
                        w-32 w-full
                      "
                    >
                      <span class="mr-2">Afficher {{ limit }}</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                      />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item
                        :key="i"
                        v-for="(r, i) in rowsTable"
                        @click="limit = r"
                      >
                        <span class="flex items-center">
                          <span>{{ r }}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
              <div>
                <vs-dropdown class="dd-actions cursor-pointer ml-4">
                  <div
                    class="
                      p-3
                      shadow-drop
                      rounded-lg
                      d-theme-dark-bg
                      cursor-pointer
                      flex
                      items-center
                      justify-center
                      text-lg
                      font-medium
                      w-32 w-full
                    "
                  >
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="activePrompt = true">
                      <span class="flex items-center">
                        <feather-icon
                          icon="PrinterIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Exporter</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      :disabled="documentSelected.length == 0"
                      @click="checkRemoveMultipleDocumentItem"
                    >
                      <span class="flex items-center">
                        <feather-icon
                          icon="Trash2Icon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Supprimer la sélection</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </div>

            <div class="clearfix mb-3 mt-6">
              <div>
                <div class="w-auto" v-if="loadingListing">
                  <clip-loader
                    :loading="loadingListing"
                    color="primary"
                  ></clip-loader>
                </div>
                <div v-else>
                  <div v-if="allDocuments.list.length !== 0">
                    <vs-table
                      pagination
                      multiple
                      :data="allDocuments.list"
                      v-model="documentSelected"
                      search
                      noDataText="Aucun résultat trouvé"
                      stripe
                      :max-items="limit != 'Tout' ? limit : total"
                    >
                      <template slot="thead">
                        <vs-th>N°</vs-th>

                        <vs-th sort-key="name">Nom du Fichier</vs-th>
                        <vs-th sort-key="createdAt">Ajouté le</vs-th>
                        <vs-th sort-key="updatedAt">Modifié le</vs-th>
                        <vs-th>Actions</vs-th>
                      </template>
                      <template slot-scope="{ data }">
                        <vs-tr
                          :data="tr"
                          :key="indextr"
                          v-for="(tr, indextr) in data"
                        >
                          <vs-td :data="data[indextr].id">{{
                            indextr + 1
                          }}</vs-td>
                          <vs-td :data="data[indextr].name">{{
                            data[indextr].name
                          }}</vs-td>

                          <vs-td :data="data[indextr].createdAt">{{
                            dateElement(data[indextr]).createdAtInfo
                          }}</vs-td>

                          <vs-td :data="data[indextr].updatedAt">{{
                            dateElement(data[indextr]).textUpdatedAt
                          }}</vs-td>

                          <vs-td :data="data[indextr].id">
                            <div class="inline-flex" @click.stop>
                              <!-- <div>
                                <vx-tooltip color="primary" text="Visualiser">
                                  <div
                                    class="cursor-pointer"
                                    @click="checkViewDocument(data[indextr])"
                                  >
                                    <feather-icon
                                      icon="EyeIcon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                </vx-tooltip>
                              </div> -->
                              <div>
                                <vx-tooltip color="primary" text="Télécharger">
                                  <div
                                    class="ml-8 cursor-pointer"
                                    @click="downloadDocument(data[indextr])"
                                  >
                                    <feather-icon
                                      icon="DownloadIcon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                </vx-tooltip>
                              </div>
                              <div>
                                <vx-tooltip
                                  color="primary"
                                  text="Editer"
                                  v-if="
                                    checkUserRole('customer_document').update
                                  "
                                >
                                  <div class="cursor-pointer ml-8">
                                    <feather-icon
                                      @click="
                                        checkUpdatedDocument(data[indextr])
                                      "
                                      icon="Edit2Icon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                </vx-tooltip>
                                <vx-tooltip
                                  v-else
                                  color="primary"
                                  :title="role.notAllowTitle"
                                  :text="role.notAllowText"
                                >
                                  <div class="ml-8 cursor-pointer">
                                    <feather-icon
                                      icon="Edit2Icon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                </vx-tooltip>
                              </div>

                              <div>
                                <vx-tooltip
                                  color="primary"
                                  text="Supprimer"
                                  v-if="
                                    checkUserRole('customer_document').delete &&
                                    docSystemVerify(data[indextr].code)
                                  "
                                >
                                  <div class="cursor-pointer ml-8">
                                    <feather-icon
                                      @click="
                                        checkRemoveDocument(data[indextr])
                                      "
                                      icon="Trash2Icon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                </vx-tooltip>
                                <vx-tooltip
                                  v-else
                                  color="primary"
                                  :title="role.notAllowTitle"
                                  :text="role.notAllowText"
                                >
                                  <div class="ml-8 cursor-pointer">
                                    <feather-icon
                                      icon="Trash2Icon"
                                      svgClasses="w-6 h-6"
                                    ></feather-icon>
                                  </div>
                                </vx-tooltip>
                              </div>
                              <vx-tooltip
                                color="primary"
                                :title="aboutElement(data[indextr]).title"
                                :text="aboutElement(data[indextr]).text"
                              >
                                <div class="ml-8 cursor-pointer">
                                  <feather-icon
                                    icon="InfoIcon"
                                    svgClasses="w-6 h-6"
                                  ></feather-icon>
                                </div>
                              </vx-tooltip>
                              <div
                                class="ml-2"
                                v-show="woIsAction == data[indextr].id"
                              >
                                <clip-loader
                                  :loading="loadingRemoveSlip"
                                  color="primary"
                                  size="25px"
                                ></clip-loader>
                              </div>
                            </div>
                          </vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>
                    <vs-pagination
                      class="mt-6"
                      :total="allPages"
                      v-model="currentPage"
                    ></vs-pagination>
                  </div>
                  <div v-else>
                    <div class="flex items-center justify-center mt-8">
                      <strong>Aucun enregistrement</strong>
                    </div>
                  </div>
                </div>
              </div>

              <!--Ajouter document-->
              <vs-popup
                class="holamundo"
                title="Ajouter un Document"
                :active.sync="popupAddDocumentActive"
              >
                <div class="m-4">
                  <div class="clearfix">
                    <div>
                      <vs-row vs-w="12">
                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              class="inputx w-full mb-2 mt-2"
                              v-validate="'required'"
                              data-vv-validate-on="blur"
                              label="Nom du fichier *"
                              v-model="form.name"
                              name="Nom du fichier"
                            />
                            <div class="aps-benin-v2-error-msg">
                              {{ errors.first("Nom du fichier") }}
                            </div>
                          </div>
                        </vs-col>
                        <vs-col
                          class="pr-4 mt-2 mb-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              class="inputx w-full mb-2 mt-2"
                              label="Code (système) fichier"
                              v-model="form.code"
                            />
                          </div>
                        </vs-col>
                        <vs-col
                          class="pr-4 mt-2 mb-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <div>
                              <div class="ml-2">Fichier*</div>
                              <div
                                class="uk-margin"
                                style="margin-top: 5px !important"
                              >
                                <div uk-form-custom class="ebsi-upload-file">
                                  <input
                                    type="file"
                                    v-on:change="checkUploadFile"
                                    name="Fichier"
                                  />
                                  <button
                                    style="cursor: pointer"
                                    class="uk-button uk-button-default"
                                    type="button"
                                    tabindex="-1"
                                  >
                                    Choisir un fichier
                                  </button>
                                  <div class="aps-benin-v2-error-msg">
                                    {{ errors.first("Fichier") }}
                                  </div>
                                  <div
                                    class="mt-6 mb-3"
                                    style="
                                      display: flex;
                                      flex-direction: column;
                                    "
                                  >
                                    <span v-if="displayFileName">
                                      <strong>{{ form.filename }}</strong>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="aps-benin-v2-error-msg"
                                v-show="formError"
                              >
                                Erreur lors du chargement du fichier
                              </div>
                            </div>
                          </div>
                        </vs-col>
                        <vs-col
                          class="pr-4 mt-2 mb-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <ul class="centerx">
                              <li>
                                <div class="flex">
                                  Visibilité
                                  <vs-checkbox
                                    class="ml-2"
                                    v-model="form.visibility"
                                  ></vs-checkbox>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </vs-col>
                      </vs-row>
                    </div>
                  </div>
                  <div class="mt-8">
                    <div class="inline-flex">
                      <div>
                        <vs-button
                          class="w-full sm:w-auto"
                          :disabled="
                            !validateFormAddDocument || loadingAddDocument
                          "
                          @click="fetchApplyAddDocument"
                          >Valider</vs-button
                        >
                        <vs-button
                          class="w-full sm:w-auto ml-4"
                          type="border"
                          @click="popupAddDocumentActive = false"
                          >Annuler</vs-button
                        >
                      </div>
                      <div class="ml-2">
                        <clip-loader
                          :loading="loadingAddDocument"
                          color="primary"
                        ></clip-loader>
                      </div>
                    </div>
                    <div class="mt-2">
                      <p class="text-danger">* Champ obligatoire</p>
                    </div>
                  </div>
                </div>
              </vs-popup>

              <!--Modifier document-->
              <vs-popup
                class="holamundo"
                title="Modifier un Document"
                :active.sync="popupUpdatedDocumentActive"
              >
                <div class="m-4">
                  <div class="clearfix">
                    <div>
                      <vs-row vs-w="12">
                        <vs-col
                          class="pr-4"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              class="inputx w-full"
                              v-validate="'required'"
                              label="Nom du fichier*"
                              data-vv-validate-on="blur"
                              v-model="formUpdated.name"
                              name="Nom du fichier"
                            />
                            <div class="aps-benin-v2-error-msg">
                              {{ errors.first("Nom du fichier") }}
                            </div>
                          </div>
                        </vs-col>
                        <vs-col
                          class="pr-4 mt-2 mb-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <vs-input
                              disabled
                              class="inputx w-full"
                              label="Code (système) fichier "
                              v-model="formUpdated.code"
                            />
                          </div>
                        </vs-col>
                        <vs-col
                          class="pr-4 mt-2 mb-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <div>
                              <div class="ml-2">Fichier*</div>
                              <div
                                class="uk-margin"
                                style="margin-top: 5px !important"
                              >
                                <div uk-form-custom class="ebsi-upload-file">
                                  <input
                                    type="file"
                                    v-on:change="checkUploadFileUpdated"
                                    name="Fichier"
                                    data-vv-validate-on="blur"
                                  />
                                  <button
                                    style="cursor: pointer"
                                    class="uk-button uk-button-default"
                                    type="button"
                                    tabindex="-1"
                                  >
                                    Choisir un fichier
                                  </button>
                                  <div class="aps-benin-v2-error-msg">
                                    {{ errors.first("Fichier") }}
                                  </div>
                                  <div
                                    class="mt-2 mb-3"
                                    style="
                                      display: flex;
                                      flex-direction: column;
                                    "
                                  >
                                    <span v-if="displayFileUpdatedName">
                                      <strong>{{
                                        formUpdated.filename
                                      }}</strong>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <span
                              class="aps-benin-v2-error-msg"
                              v-show="formError"
                              >Erreur lors du chargement du fichier</span
                            >
                            <div
                              v-if="!displayFileUpdatedName"
                              class="mt-2 mb-3"
                              style="display: flex; flex-direction: column"
                            >
                              <b
                                @click="checkViewDocument(formUpdated)"
                                class="cursor-pointer"
                                >{{ subString(formUpdated.filename) }}</b
                              >
                            </div>
                          </div>
                        </vs-col>
                        <vs-col
                          class="pr-4 mt-2 mb-2"
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          vs-lg="6"
                          vs-sm="6"
                          vs-xs="12"
                        >
                          <div class="w-full">
                            <ul class="centerx">
                              <li>
                                <vs-checkbox v-model="formUpdated.visibility">
                                  <span class="mr-2">Visibilité</span>
                                </vs-checkbox>
                              </li>
                            </ul>
                          </div>
                        </vs-col>
                      </vs-row>
                    </div>
                  </div>
                  <div class="mt-8">
                    <div class="inline-flex">
                      <div>
                        <vs-button
                          class="w-full sm:w-auto"
                          :disabled="
                            !validateFormUpdateDocument ||
                            loadingUpdatedDocument
                          "
                          @click="fetchApplyUpdateDocument"
                          >Valider</vs-button
                        >
                        <vs-button
                          class="w-full sm:w-auto ml-4"
                          type="border"
                          @click="popupUpdatedDocumentActive = false"
                          >Annuler</vs-button
                        >
                      </div>
                      <div class="ml-2">
                        <clip-loader
                          :loading="loadingUpdatedDocument"
                          color="primary"
                        ></clip-loader>
                      </div>
                    </div>
                    <div class="mt-2">
                      <p class="text-danger">* Champ obligatoire</p>
                    </div>
                  </div>
                </div>
              </vs-popup>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <vx-card title="Tarifs frais de santé & prévoyance" class="mb-4">
      <div slot="no-body">
        <div class="aps-benin-v2-content">
          <div v-if="loading">
            <clip-loader :loading="loading" color="primary"></clip-loader>
          </div>
          <div v-else>
            <div>
              <div class="con-exemple-prompt mb-4">
                <vs-row vs-w="12">
                  <!--vs-col
                    class="pr-4"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="12"
                  >
                    <div class="w-full">
                      <div>
                        <strong
                          ><label for="" class="vs-input--label"
                            >COTISATION ANNUELLE TTC *</label
                          ></strong
                        >
                      </div>
                      <vs-textarea
                        v-model="formPicto.cotisationTTC"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        name="COTISATION ANNUELLE TTC"
                      />
                      <div class="aps-benin-v2-error-msg">
                        {{ errors.first("COTISATION ANNUELLE TTC") }}
                      </div>
                    </div>
                  </vs-col>
                  <vs-col
                    class="pr-4 mb-2 mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="12"
                  >
                    <div class="w-full">
                      <div>
                        <strong
                          ><label for="" class="vs-input--label"
                            >PLAFOND FRAIS DE SANTÉ *</label
                          ></strong
                        >
                      </div>
                      <vs-textarea
                        v-model="formPicto.plafondSante"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        name="PLAFOND FRAIS DE SANTÉ"
                      />
                      <div class="aps-benin-v2-error-msg">
                        {{ errors.first("PLAFOND FRAIS DE SANTÉ") }}
                      </div>
                    </div>
                  </vs-col>
                  <vs-col
                    class="pr-4 mb-2 mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="12"
                  >
                    <div class="w-full">
                      <div>
                        <strong
                          ><label for="" class="vs-input--label"
                            >NIVEAU DE COUVERTURE *</label
                          ></strong
                        >
                      </div>
                      <vs-textarea
                        v-model="formPicto.niveauCouveture"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        name="NIVEAU DE COUVERTURE"
                      />
                      <div class="aps-benin-v2-error-msg">
                        {{ errors.first("NIVEAU DE COUVERTURE") }}
                      </div>
                    </div>
                  </vs-col>
                  <vs-col
                    class="pr-4 mb-2 mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="12"
                  >
                    <div class="w-full">
                      <div>
                        <strong
                          ><label for="" class="vs-input--label"
                            >TYPE DE CONTRAT *</label
                          ></strong
                        >
                      </div>
                      <vs-textarea
                        v-model="formPicto.typeContrat"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        name="TYPE DE CONTRAT"
                      />
                      <div class="aps-benin-v2-error-msg">
                        {{ errors.first("TYPE DE CONTRAT") }}
                      </div>
                    </div>
                  </vs-col>-->
                  <vs-col
                    class="pr-4 mb-2 mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="12"
                  >
                    <div class="w-full">
                      <div>
                        <strong
                          ><label for="" class="vs-input--label"
                            >PRÉVOYANCE ASSURÉ PRINCIPAL *</label
                          ></strong
                        >
                      </div>
                      <vs-textarea
                        v-model="formPicto.prevoyanceAssure"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        name="PRÉVOYANCE ASSURÉ PRINCIPAL"
                      />
                      <div class="aps-benin-v2-error-msg">
                        {{ errors.first("PRÉVOYANCE ASSURÉ PRINCIPAL") }}
                      </div>
                    </div>
                  </vs-col>
                  <vs-col
                    class="pr-4 mb-2 mt-2"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="12"
                  >
                    <div class="w-full">
                      <div>
                        <strong
                          ><label for="" class="vs-input--label"
                            >PRÉVOYANCE CONJOINT *</label
                          ></strong
                        >
                      </div>
                      <vs-textarea
                        v-model="formPicto.prevoyanceConjoint"
                        v-validate="'required'"
                        data-vv-validate-on="blur"
                        name="PRÉVOYANCE CONJOINT"
                      />
                      <div class="aps-benin-v2-error-msg">
                        {{ errors.first("PRÉVOYANCE CONJOINT") }}
                      </div>
                    </div>
                  </vs-col>
                </vs-row>
              </div>

              <div class="pt-2 pb-2">
                <div class="inline-flex">
                  <div>
                    <vs-button
                      v-if="checkUserRole('customer_document').update"
                      :disabled="!validatePicto || loadingPictoData"
                      @click="fetchApplyUpdatePictoData"
                      class="w-full sm:w-auto"
                      >Mettre à jour</vs-button
                    >
                    <vx-tooltip
                      color="primary"
                      :title="role.notAllowTitle"
                      :text="role.notAllowText"
                      v-else
                    >
                      <vs-button :disabled="true" class="w-full sm:w-auto m-2"
                        >Mettre à jour</vs-button
                      >
                    </vx-tooltip>
                  </div>
                  <div class="w-auto" v-if="loadingPictoData">
                    <clip-loader
                      :loading="loadingPictoData"
                      color="primary"
                    ></clip-loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import vSelect from "vue-select";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import global from "@/config/global";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
export default {
  name: "aps-benin-v2-doc-client",
  data() {
    return {
      resetFilesUpload: "",

      form: {
        name: "",
        filename: "",
        code: "",
        visibility: false,
      },
      displayValidityDate: false,
      changeFileForUpdated: false,
      formUpdated: {
        name: "",
        filename: "",
        code: "",
        visibility: false,
      },
      limit: 1,
      skip: 0,
      allPages: 1,
      currentPage: 1,
      crrID: "",
      crrIDs: [],

      role: {},
      global: {},
      formData: {},
      formDataUpdated: {},
      documentSelected: [],
      woIsAction: "",
      fileName: "",
      displayFileName: false,
      displayFileUpdatedName: false,
      activePrompt: false,
      cellAutoWidth: true,

      popupAddDocumentActive: false,
      popupUpdatedDocumentActive: false,

      loading: false,
      loadingListing: false,
      loadingValidityDate: false,
      loadingUpdatedDocument: false,
      loadingRemoveSlip: false,
      loadingRemoveDocument: false,
      loadingAddDocument: false,
      loadingDownloadDocument: false,
      loadingValidilyDateMail: false,

      validityDate: "",

      error: false,
      formError: false,
      errorMsg: "",
      formats: ["xlsx", "csv", "txt"], //formats: ["xlsx", "csv", "txt", "pdf"],
      selectedFormat: "xlsx",
      headerVal: ["index", "name", "createdAt"],
      headerTitle: ["N°", "Nom du fichier", "Ajouté le"],

      loadingPictoData: false,
      formPicto: {
        prevoyanceAssure: "",
        prevoyanceConjoint: "",
      },
    };
  },
  props: {
    productName: String,
  },
  created() {
    Validator.localize("fr", VeeValidateFR);
    this.role = role;
    this.limit = this.rowsTable[1];
    this.getItem({ name: this.productName });
  },

  components: {
    ClipLoader,
    vSelect,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("customerDocument", ["allDocuments"]),
    ...mapGetters("general", ["rowsTable"]),
    ...mapGetters("customerDocument", ["test"]),
    validateFormAddDocument() {
      return (
        // !this.errors.any() && this.form.name != "" && this.form.filename != ""
        this.form.name != "" && this.form.filename != ""
      );
    },
    validateFormUpdateDocument() {
      return (
        !this.errors.any() &&
        this.formUpdated.name != "" &&
        this.formUpdated.filename != ""
      );
    },
    validateForm() {
      return !this.errors.any() && this.validityDate != "";
    },
    validatePicto() {
      return (
        //this.formPicto.cotisationTTC != "" &&
        //this.formPicto.plafondSante != "" &&
        //this.formPicto.niveauCouveture != "" &&
        //this.formPicto.typeContrat != "" &&
        this.formPicto.prevoyanceAssure != "" &&
        this.formPicto.prevoyanceConjoint != ""
      );
    },
  },

  methods: {
    ...mapActions("general", [
      "applyUploadFile",
      "applyGetSetting",
      "applyAddSetting",
    ]),
    ...mapActions("customerDocument", [
      "applyPutDocument",
      "applyAddDocument",
      "applyGetAllDocuments",
      "applyRemoveDocument",
      "applyGetDocument",
    ]),
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    formatDate(data) {
      return mixin.methods.formatDate(data);
    },
    clearFields() {
      (this.filename = ""),
        (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },
    docSystemVerify(code) {
      let listCode = [
        // SOHU
        "by_bulletin_adhesion_sohu",
        "by_certificat_sohu",
        "by_condition_general_sohu",
        "by_appel_cotisation_sohu",
        "by_appel_cotisation_solde_sohu",
        "by_fiche_produit_sohu",
        "by_questionnaire_medical_sohu",
        "by_simulation_tarifaire_sohu_1",
        "by_simulation_tarifaire_sohu_2",
        "by_simulation_tarifaire_sohu_3",
        "tarifs_sohu",
      ];

      if (listCode.indexOf(code) >= 0) {
        return false;
      } else {
        return true;
      }
    },

    ////  EXORTER DOCUMENT
    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];
        let _this = this;

        this.allDocuments.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            createdAt: _this.dateElement(el).createdAtInfo,
            name: el.name,
          };
          tableToPrintPdf.push(jsonTmp);
        });
        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName
        );
      } else {
        let tableToPrint = [];
        let _this = this;
        this.allDocuments.list.forEach((el) => {
          let jsonTmp = {
            createdAt: _this.dateElement(el).createdAtInfo,
            name: el.name,
            code: el.code,
            attachFile: this.showFileData(el.filename),
            visibility: el.visibility ? "OUI" : "NON",
            infos: this.aboutElement(el).text,
          };

          tableToPrint.push(jsonTmp);
        });

        let headerTitleTmp = [
          "Ajouté le",
          "Nom du document",
          "Code",
          "Visible",
          "Fichiers",
          "Informations",
        ];

        let headerValTmp = [
          "createdAt",
          "name",
          "code",
          "visibility",
          "attachFile",
          "infos",
        ];

        mixin.methods.exportToData(
          [...tableToPrint],
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },
    getFilesLink(data) {
      return data
        .map((acc) => `${acc.name}(${this.showFileData(acc.filename)})`)
        .join(", ");
    },
    async checkUploadFile(evt) {
      this.formData = new FormData();

      this.formData.append("uri", evt.target.files[0]);
      this.formError = false;

      try {
        this.form.filename = evt.target.files[0].name;
        this.displayFileName = true;
      } catch (err) {
        this.formError = true;
      }
    },
    async checkUploadFileUpdated(evt) {
      this.changeFileForUpdated = true;
      this.formDataUpdated = new FormData();

      this.formDataUpdated.append("uri", evt.target.files[0]);
      this.formError = false;

      try {
        this.formUpdated.filename = evt.target.files[0].name;
        this.displayFileUpdatedName = true;
      } catch (err) {
        this.formError = true;
      }
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    ////VISUALISER DOCUMENT
    checkViewDocument(item) {
      window.open(this.showFileData(item.filename), "_blank");
    },
    showFileData(data) {
      if (data == "") {
        return "";
      } else {
        return `${this.$config.serverURL}/downloads/${data}`;
      }
    },
    subString(data) {
      if (data.length > 40) {
        return `${data.substring(0, 40)} ...`;
      } else {
        return data;
      }
    },
    //// TELECHARGER DOCUMENT
    downloadDocument(data) {
      this.loadingDownloadDocument = true;
      try {
        // var FileSaver = require("file-saver");

        // FileSaver.saveAs(
        //   `${this.$config.serverURL}/downloads/${data.filename}`,
        //   `${data.name}`
        // );

        window.open(this.showFileData(data.filename), "_blank");

        this.loadingDownloadDocument = false;
      } catch (error) {
        this.loadingDownloadDocument = false;
      }

      this.popupActiveDownload = true;
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },

    /// AJOUTER UN DOCUMENT
    async fetchApplyAddDocument() {
      this.loadingAddDocument = true;
      try {
        let fileDataUp = await this.applyUploadFile(this.formData);

        let data = {
          ...this.form,
          filename: fileDataUp.data[0].uploadedName,
          productName: this.productName,
        };
        await this.applyAddDocument(data);
        this.acceptAlert(
          mixin.methods.message("customer_document", "add").success
        );

        this.loadingAddDocument = false;
        this.popupAddDocumentActive = false;

        // this.acceptAlert(loadingDownloadFile);
        this.form = {};
        this.displayFileName = false;

        this.popupAddDocumentActive = false;
      } catch (err) {
        this.loadingAddDocument = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupAddDocumentActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else if (err.response.data.errors) {
            this.acceptAlert("danger", "Ajout", "Une erreur s'est produite");
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    mixin.methods.message("customer_document", "add").error
                  );

                  break;
              }
            });
          } else if (err.response.data.code) {
            if (err.response.data.code == "E_MISSING_OR_INVALID_PARAMS") {
              this.acceptAlert(
                "danger",
                "Ajout",
                "certains champs ont été omis! merci de les renseigner"
              );
            }
          }
        }
      }
    },

    /// CHECK MODIFIER UN DOCUMENT
    async checkUpdatedDocument(doc) {
      this.formUpdated = {
        ...doc,
      };

      this.crrID = doc.id;
      this.popupUpdatedDocumentActive = true;
    },

    ///MODIFIER DOCUMENT
    async fetchApplyUpdateDocument() {
      this.loadingUpdatedDocument = true;
      if (!this.validateFormUpdateDocument) return false;

      let data = {
        ...this.formUpdated,
        docId: this.formUpdated.id,
      };
      if (this.changeFileForUpdated) {
        let fileDataUp = await this.applyUploadFile(this.formDataUpdated);
        data.filename = fileDataUp.data[0].uploadedName;
      }

      let _this = this;

      try {
        await this.applyPutDocument(data).then((res) => {
          this.formUpdated = {
            name: "",
            filename: "",
            code: "",
            visibility: false,
          };
          this.displayFileUpdatedName = false;
          this.loadingUpdatedDocument = false;
          this.popupUpdatedDocumentActive = false;
          this.changeFileForUpdated = false;
          this.acceptAlert(
            mixin.methods.message("customer_document", "update").success
          );
        });
      } catch (err) {
        this.loadingUpdatedDocument = false;
        this.changeFileForUpdated = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupUpdatedDocumentActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else if (err.response.data.errors) {
            this.acceptAlert("danger", "Ajout", "Une erreur s'est produite");
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    mixin.methods.message("customer_document", "update").error
                  );

                  break;
              }
            });
          } else if (err.response.data.code) {
            if (err.response.data.code == "E_MISSING_OR_INVALID_PARAMS") {
              this.acceptAlert(
                "danger",
                "Ajout",
                "certains champs ont été omis! merci de les renseigner"
              );
            }
          }
        }
      }
    },

    ///CHECK SUPPRIMER DOCUMENT

    checkRemoveDocument(doc) {
      this.crrID = doc.id;
      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer ${doc.name} ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveDocument,
      });
    },

    /////SUPPRIMER DOCUMENT
    async fetchApplyRemoveDocument() {
      this.woIsAction = this.crrID;
      let data = {
        docId: [this.crrID],
      };

      this.loadingRemoveDocument = true;
      try {
        await this.applyRemoveDocument(data);
        this.crrID = "";
        this.documentSelected = [];
        this.loadingRemoveDocument = false;
        this.acceptAlert(
          mixin.methods.message("customer_document", "delete").success
        );

        this.woIsAction = "";
      } catch (err) {
        this.loadingRemoveDocument = false;
        this.woIsAction = "";
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "docNotFound":
                  this.errorMsg = "Ce fichier n'existe pas";
                  this.acceptAlert(
                    "danger",
                    "Suppression",
                    "Document Client inexistant"
                  );
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("customer_document", "delete").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    checkRemoveMultipleDocumentItem() {
      this.documentSelected.forEach((element) => {
        this.crrIDs.push(element.id);
      });

      this.$vs.dialog({
        type: "confirm",
        color: "primary",
        title: `Confirmation`,
        text: `Voulez-vous vraiment supprimer les éléments sélectionnés ?`,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyRemoveMultipleDocument,
      });
    },
    async fetchApplyRemoveMultipleDocument() {
      let data = {
        docId: this.crrIDs,
      };

      this.loading = true;
      try {
        await this.applyRemoveDocument(data);
        this.loading = false;
        this.documentSelected = [];
        this.crrIDs = [];

        this.acceptAlert(
          mixin.methods.message("customer_document", "multipleDeletion").success
        );

        this.woIsAction = "";
      } catch (err) {
        this.loading = false;
        this.foldersSelected = [];
        this.crrIDs = [];
        this.woIsAction = "";
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "docNotFound":
                  this.errorMsg = "Document Client inexistant";
                  this.acceptAlert("danger", "Mise à jour", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message(
                      "customer_document",
                      "multipleDeletion"
                    ).error
                  );

                  break;
              }
            });
          }
        }
      }
    },

    //////LISTING
    async fetchAllDocuments(item) {
      this.loadingListing = true;

      try {
        await this.applyGetAllDocuments(item);
        this.total = this.allDocuments.total;
        let p = parseInt((this.total / this.limit).toFixed(0), 10);
        this.allPages = p ? p : 1;

        this.loadingListing = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loadingListing = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                default:
                  this.acceptAlert(
                    "danger",
                    "Mise à jour",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async getItem(item) {
      this.loadingValidilyDate = true;
      try {
        let recupItem = await this.applyGetSetting(item);
        this.validityDate = recupItem.data.value;

        // les données des picto
        let pictoData = await this.applyGetSetting({ name: this.productName + "_picto_tarifs" })
        
        if(pictoData.data) {
          this.formPicto = pictoData.data.value
        }

        this.loadingAddMail = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loadingAddMail = false;
        this.error = true;
        this.errorMsg = "";
      }
    },
    async fetchApplyUpdateValidityDate() {
      if (!this.validateForm) return false;

      this.loadingValidityDate = true;

      try {
        if (this.validityDate == "") {
          this.displayValidityDate = true;
        } else {
          this.displayValidityDate = false;
          await this.applyAddSetting({
            name: this.productName,
            value: this.validityDate,
          });
          this.loadingValidityDate = false;
          this.acceptAlert(
            mixin.methods.message("customer_account", "validity").success
          );
        }
      } catch (err) {
        this.loadingValidityDate = false;
        this.error = true;
        this.errorMsg = "";
      }
    },
    async fetchApplyUpdatePictoData() {
      if (!this.validatePicto) return false;

      this.loadingPictoData = true;

      try {
        await this.applyAddSetting({
          name: this.productName + "_picto_tarifs",
          value: this.formPicto,
        })

        this.loadingPictoData = false;
        this.acceptAlert(
          mixin.methods.message("customer_account", "pictoTarifs").success
        )
      } catch (err) {
        this.loadingPictoData = false;
        this.error = true;
        this.errorMsg = "";
        this.acceptAlert(
          mixin.methods.message("customer_account", "pictoTarifs").error
        )
      }
    },
  },
  watch: {
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchAllDocuments({
        limit: this.limit,
        skip: this.skip,
        productName: this.productName,
      });
    },

    limit() {
      this.skip = 0;

      this.fetchAllDocuments({
        limit: this.limit,
        skip: this.skip,
        productName: this.productName,
      });
    },
  },
};
</script>
